import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`500M Ski Erg`}</p>
    <p>{`25-Back Extensions`}</p>
    <p>{`25-Ring Pushups`}</p>
    <p>{`25-Pullups`}</p>
    <p>{`400M Ski Erg`}</p>
    <p>{`20-Back Extension`}</p>
    <p>{`20-Ring Pushups`}</p>
    <p>{`20-Pullups`}</p>
    <p>{`300M Ski Erg`}</p>
    <p>{`15-Back Extensions`}</p>
    <p>{`15-Ring Pushups`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`200M Ski Erg`}</p>
    <p>{`10-Back Extensions`}</p>
    <p>{`10-Ring Pushups`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`100M Ski Erg`}</p>
    <p>{`5-Back Extensions`}</p>
    <p>{`5-Ring Pushups`}</p>
    <p>{`5-Pullups`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      